import Vimeo from './Vimeo';
import Youtube from './Youtube';

export default class Video {
  constructor(el, options) {
    this.el = el;
    this.options = options;
    this.platform = el.getAttribute('data-video').trim();
    this.playButton = el.querySelector('[data-video-play-button]');
    this.videoEl = el.querySelector('[data-video-element]');

    this.onPlayClick = this._onPlayClick.bind(this);
    this.onPauseClick = this._onPauseClick.bind(this);
    this.autoplay = this._autoplay.bind(this);

    this.video = null;

    this.videoData = {
      el: this.videoEl.childNodes[0],
      videoUrl: this.videoEl.getAttribute('data-video-url'),
      loop: this.options && this.options.loop,
    };

    switch (this.platform) {
      case 'youtube':
        this.video = new Youtube({
          el: this.videoEl.childNodes[0],
          videoUrl: this.videoEl.getAttribute('data-video-url'),
          loop: this.options && this.options.loop,
        });
        break;
      case 'vimeo':
        this.video = new Vimeo({
          el: this.videoEl,
          videoUrl: this.videoEl.getAttribute('data-video-url'),
          loop: this.options && this.options.loop,
        });
        break;
      default:
        this.video = null;
        break;
    }

    this.el.addEventListener('click', this.onPlayClick);

    if (this.playButton) {
      if (this.options && this.options.autoplay) {
        this.autoplay();
      }

      this.playButton.addEventListener('click', this.onPlayClick);
    }
  }

  _onPlayClick() {
    this.el.classList.add('video-loading');

    this.video.play().then(() => {
      this.el.classList.add('video-transitioning');

      setTimeout(() => {
        this.el.classList.remove('video-loading');
        this.el.classList.remove('video-transitioning');
        this.el.classList.add('video-playing');
      }, 200);
    });
  }

  _onPauseClick() {
    this.video.pause();
  }

  _autoplay() {
    this.el.classList.add('video-loading');

    this.video.autoplay().then(() => {
      this.el.classList.add('video-transitioning');

      setTimeout(() => {
        this.el.classList.remove('video-loading');
        this.el.classList.remove('video-transitioning');
        this.el.classList.add('video-playing');
      }, 200);
    });
  }

  play() {
    this._onPlayClick();
  }

  pause() {
    this._onPauseClick();
  }

  unload() {
    this.el.removeEventListener('click', this.onPlayClick);

    if (this.playButton) {
      this.playButton.removeEventListener('click', this.onPlayClick);
    }

    if (this.video) {
      this.video.unload();
    }
  }

  destroy() {
    this.unload();
  }
}
