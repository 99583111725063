import Splide from '@splidejs/splide';

const selectors = {
  mainCarousel: '[data-featured-testimonial-carousel]',
  thumbnailCarousel: '[data-featured-testimonial-thumbnail-carousel]',
};

export default class DynamicFeaturedTestimonials {
  constructor(section) {
    const carousel = section.el.querySelector(selectors.mainCarousel);
    const thumbnailCarousel = section.el.querySelector(
      selectors.thumbnailCarousel,
    );
    if (carousel && thumbnailCarousel) {
      const mainCarousel = new Splide(carousel, {
        perPage: 1,
        perMove: 1,
        type: 'fade',
        arrows: true,
        autoplay: true,
        pagination: false,
        drag: false,
        rewind: true,
      });

      const thumbnails = new Splide(thumbnailCarousel, {
        perPage: 1,
        perMove: 1,
        type: 'fade',
        arrows: false,
        pagination: false,
        drag: false,
      });

      mainCarousel.sync(thumbnails);
      mainCarousel.mount();
      thumbnails.mount();
    }
  }
}
