import DropDown from '@knitagency/agency-utils/dist/DropDown';

export default class DynamicFaq {
  constructor(section) {
    this._initDropDown(section);
  }

  _initDropDown(section) {
    const el = section.el.querySelector('[data-faq-block]');

    if (el) {
      new DropDown({
        el: el,
        triggerClass: 'faq-trigger',
        contentClass: 'faq-content',
        closeOnClickOutside: false,
      });
    }
  }
}
