import DynamicBrandTestimonials from '../scripts/sections/DynamicBrandTestimonials';
import DynamicCenteredFeature from '../scripts/sections/DynamicCenteredFeature';
import DynamicComparisonTable from '../scripts/sections/DynamicComparisonTable';
import DynamicFaq from '../scripts/sections/DynamicFaq';
import DynamicFeaturedBlogPost from '../scripts/sections/DynamicFeaturedBlogPost';
import DynamicFeaturedCollection from '../scripts/sections/DynamicFeaturedCollection';
import DynamicFeaturedProduct from '../scripts/sections/DynamicFeaturedProduct';
import DynamicFeaturedTestimonials from '../scripts/sections/DynamicFeaturedTestimonials';
import DynamicHero from '../scripts/sections/DynamicHero';
import DynamicMediaWithText from '../scripts/sections/DynamicMediaWithText';

// Sections
import MainAddresses from '../scripts/sections/MainAddresses';
import MainArticle from '../scripts/sections/MainArticle';
import MainCollection from '../scripts/sections/MainCollection';
import MainFaq from '../scripts/sections/MainFaq';
import MainHeader from '../scripts/sections/MainHeader';
import MainProduct from '../scripts/sections/MainProduct';
import MainProductRecommendations from '../scripts/sections/MainProductRecommendations';
import MainSearch from '../scripts/sections/MainSearch';
import Sections from '@pixelunion/shopify-sections-manager';

// Components
import LocalizationForm from '../scripts/components/LocalizationForm';
import StickyHeader from '../scripts/components/StickyHeader';

// React
import mountPreact from '../scripts/preact/mountPreact';

// Init global sections
const sections = new Sections();
sections.register('main-article', section => new MainArticle(section), {
  lazy: true,
});
sections.register('main-collection', section => new MainCollection(section));
sections.register('main-product', section => new MainProduct(section));
sections.register('main-addresses', section => new MainAddresses(section));
sections.register(
  'main-collection-grid',
  section => new MainCollection(section),
);
sections.register('main-header', section => new MainHeader(section));
sections.register('main-faq', section => new MainFaq(section));

sections.register(
  'dynamic-centered-feature',
  section => new DynamicCenteredFeature(section),
  { lazy: true },
);
sections.register(
  'dynamic-comparison-table',
  section => new DynamicComparisonTable(section),
  { lazy: true },
);
sections.register(
  'dynamic-brand-testimonials',
  section => new DynamicBrandTestimonials(section),
  { lazy: true },
);
sections.register(
  'dynamic-featured-collection',
  section => new DynamicFeaturedCollection(section),
  { lazy: true },
);
sections.register(
  'dynamic-featured-product',
  section => new DynamicFeaturedProduct(section),
  {
    lazy: true,
  },
);
sections.register(
  'dynamic-media-w-text',
  section => new DynamicMediaWithText(section),
  {
    lazy: true,
  },
);
sections.register('dynamic-hero', section => new DynamicHero(section), {
  lazy: true,
});
sections.register('dynamic-faq', section => new DynamicFaq(section), {
  lazy: true,
});

sections.register(
  'dynamic-featured-blog-post',
  section => new DynamicFeaturedBlogPost(section),
  {
    lazy: true,
  },
);

sections.register(
  'dynamic-featured-testimonials',
  section => new DynamicFeaturedTestimonials(section),
  {
    lazy: true,
  },
);

sections.register(
  'main-product-recommendations',
  section => new MainProductRecommendations(section),
  {
    lazy: true,
  },
);

sections.register('main-search', section => new MainSearch(section));

// Init language switcher
const selectors = document.querySelectorAll('[data-country-selector]');
selectors.forEach(selector => new LocalizationForm(selector));

new StickyHeader();

// Init preact
mountPreact();
