import Search from '../components/search/Search';
import scrollLock from 'scroll-lock';

const selectors = {
  headerNavigation: '[data-header-navigation]',
  headerNavDetails: '[data-header-nav-details]',
  navHamburger: '[data-nav-hamburger]',
  navClose: '[data-nav-close]',
  megamenuDetails: '[data-megamenu-details]',
  megamenuOverlay: '[data-megamenu-overlay]',
  announcementBar: '[data-announcement-bar]',
};

export default class MainHeader {
  constructor(section) {
    this.section = section;
    new Search(section);

    this.initNavigation();
    this.initMobileNavigation();
    this._initMegaNav();
  }

  _initMegaNav = () => {
    const megamenuDetails = this.section.el.querySelectorAll(
      selectors.megamenuDetails,
    );
    document.addEventListener('click', e => {
      megamenuDetails.forEach(link => {
        if (e.target !== link && !link.contains(e.target)) {
          link.removeAttribute('open');
        }
      });
    });

    megamenuDetails.forEach(link => {
      const megamenuOverlay = link.querySelector(selectors.megamenuOverlay);
      megamenuOverlay.addEventListener('click', () => {
        link.removeAttribute('open');
      });
    });
  };

  initNavigation = () => {
    const linkDetails = this.section.el.querySelectorAll(
      selectors.headerNavDetails,
    );
    document.addEventListener('click', e => {
      linkDetails.forEach(link => {
        if (e.target !== link && !link.contains(e.target)) {
          link.removeAttribute('open');
        }
      });
    });
  };

  initMobileNavigation = () => {
    const headerNavigation = this.section.el.querySelector(
      selectors.headerNavigation,
    );
    const navHamburger = this.section.el.querySelector(selectors.navHamburger);
    const navClose = this.section.el.querySelector(selectors.navClose);
    navHamburger.addEventListener('click', () => {
      const announcementBar = document.querySelector(selectors.announcementBar);
      const headerHeight = this.section.el.offsetHeight;
      let announcementBarHeight = 0;
      if (announcementBar) {
        announcementBarHeight = announcementBar.offsetHeight;
      }
      const totalHeaderHeight = headerHeight + announcementBarHeight;
      headerNavigation.style.top = `${totalHeaderHeight - 1}px`;
      navHamburger.classList.add('hidden');
      navClose.classList.remove('hidden');
      headerNavigation.classList.remove('hidden');
      headerNavigation.classList.add('flex');
      scrollLock.disablePageScroll();
    });
    navClose.addEventListener('click', () => {
      navHamburger.classList.remove('hidden');
      navClose.classList.add('hidden');
      headerNavigation.classList.add('hidden');
      headerNavigation.classList.remove('flex');
      scrollLock.enablePageScroll();
    });
  };
}
