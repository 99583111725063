import AsyncView from '@pixelunion/shopify-asyncview';
import ProductItem from '../components/product/ProductItem';

const selectors = {
  container: '[data-product-recommendations]',
  productItem: '[data-product-item]',
};

export default class MainProductRecommendations {
  constructor(section) {
    const sectionId = section.id;
    const { productId } = section.data;
    const { limit } = section.data.settings;
    const container = document.querySelector(selectors.container);

    this._loadRecommendations(container, sectionId, productId, limit);
  }

  _loadRecommendations(container, sectionId, productId, limit) {
    const url = `${theme.routes.recommendations}?section_id=${sectionId}&limit=${limit}&product_id=${productId}&intent=related`;

    AsyncView.load(url, { view: '' }).then(({ html }) => {
      const fragment = document.createRange().createContextualFragment(html);
      const recommendations = fragment.querySelectorAll(selectors.productItem);

      recommendations.forEach(product => container.appendChild(product));

      const products = document.querySelectorAll(
        `${selectors.container} ${selectors.productItem}`,
      );

      products.forEach(product => new ProductItem(product));
    });
  }
}
