import PredictiveSearch from './PredictiveSearch';
import { debounce, pixelBreakpoints } from '../../helpers';

const selectors = {
  searchIcon: '[data-search-icon]',
  closeSearchIcon: '[data-close-search]',
  searchInput: '[data-search-input]',
  searchForm: '[data-search-form]',
  results: '#predictive-search',
};

export default class Search {
  constructor(element) {
    this.smBreakpoint = parseInt(pixelBreakpoints.sm.replace('px', ''));
    this.isSearchHidden = true;
    this.isResultHidden = true;

    this.searchInput = element.el.querySelector(selectors.searchInput);
    this.searchForm = element.el.querySelector(selectors.searchForm);
    this.headerNav = element.el.querySelector(selectors.headerNav);
    this.results = element.el.querySelector(selectors.results);

    this.predictiveSearch = new PredictiveSearch('predictive-search', {
      limit: 7,
      fields: 'product_type,tag,title,variants.title',
    });

    this._offFocus = this._offFocus.bind(this);
    this._toggleSearch = this._toggleSearch.bind(this);
    this._closeSearch = this._closeSearch.bind(this);
    this._openResults = this._openResults.bind(this);
    this._closeResults = this._closeResults.bind(this);

    this._initEventListeners(element);
  }

  // Initiate any event listeners that are attached to elements here
  _initEventListeners(element) {
    const searchIcon = element.el.querySelector(selectors.searchIcon);
    if (searchIcon) {
      searchIcon.addEventListener('click', this._toggleSearch);
    }
    const closeSearchIcon = element.el.querySelector(selectors.closeSearchIcon);
    if (closeSearchIcon) {
      closeSearchIcon.addEventListener('click', this._closeSearch);
    }

    document.addEventListener('click', this._offFocus);

    this.searchInput.addEventListener(
      'input',
      debounce(event => {
        this._onInput(event);
      }, 300),
    );

    this.searchInput.addEventListener('focus', this._openResults);
  }

  _toggleSearch = () => {
    if (this.isSearchHidden) {
      this._openSearch();
    } else {
      this._closeSearch();
    }
  };

  _openSearch = () => {
    this.searchInput.setAttribute('aria-hidden', 'false');
    this.searchInput.value = '';
    this.searchForm.classList.remove('hidden');
    this.searchForm.classList.add('flex');

    this.searchInput.focus();

    this.isSearchHidden = false;
  };

  _closeSearch = () => {
    this.searchInput.setAttribute('aria-hidden', 'true');
    this.searchForm.classList.remove('flex');
    this.searchForm.classList.add('hidden');

    this.isSearchHidden = true;

    if (!this.isResultHidden) {
      this._closeResults();
    }
  };

  // Check that clicked element does not contain form hide it.
  _offFocus(event) {
    if (!this.searchForm.contains(event.target) && !this.isResultHidden) {
      this._closeResults();
    }
  }

  _onInput() {
    const searchTerm = this.searchInput.value.trim();

    if (!searchTerm.length) {
      this._closeResults();
      return;
    }

    // Perform a query using the predictive search class, after receiving the response parse it and insert it in the innerHtml then display the results block.
    this.predictiveSearch
      .query(searchTerm)
      .then(response => {
        if (!response.ok) {
          const error = new Error(response.status);
          this._closeResults();
          throw error;
        }

        return response.text();
      })
      .then(text => {
        const resultsMarkup = new DOMParser()
          .parseFromString(text, 'text/html')
          .querySelector('#shopify-section-predictive-search').innerHTML;
        this.results.innerHTML = resultsMarkup;
        this._openResults();
      })
      .catch(error => {
        this._closeResults();
        throw error;
      });
  }

  _openResults() {
    this.isResultHidden = false;
    this.results.style.display = 'block';
  }

  _closeResults() {
    this.isResultHidden = true;
    this.results.style.display = 'none';
  }
}
