import CartCrossSell from './CartCrossSell';
import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import FocusTrap from 'focus-trap-react';

import cn from 'classnames';
import { createPortal } from 'preact/compat';
import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const MiniCartDrawerEl = document.getElementById('preact-mini-cart-drawer');

const transitionLength = 300;

const MiniCart = () => {
  const { cart, isMiniCartOpen, toggleMiniCart, miniCartData } =
    useCartContext();

  let percentage = 0;

  const thresholdInCents = miniCartData.settings.freeShippingThreshold * 100;
  const difference = thresholdInCents - cart.total_price;

  if (difference > 0) {
    percentage = (cart.total_price * 100) / thresholdInCents;
  } else {
    percentage = 100;
  }

  return createPortal(
    <FocusTrap active={isMiniCartOpen}>
      <div
        class={cn(
          'fixed inset-0',
          isMiniCartOpen
            ? 'opacity-1 visible'
            : `invisible opacity-0 delay-${transitionLength}`,
        )}
      >
        <div
          onClick={() => toggleMiniCart(false)}
          class={cn(
            `absolute inset-0 bg-grey-1 transition-opacity duration-${transitionLength}`,
            isMiniCartOpen ? 'opacity-50' : 'opacity-0',
          )}
        />
        <div
          class={cn(
            `absolute top-0 bottom-0 right-0 z-50 flex w-11/12 flex-col bg-grey-5 px-4 pb-4 transition-transform sm:w-full sm:max-w-md md:max-w-2xl md:pb-5 duration-${transitionLength} md:px-15`,
            isMiniCartOpen ? 'translate-x-0' : 'translate-x-full',
          )}
        >
          <div class="flex justify-end py-3 md:py-4">
            <button onClick={() => toggleMiniCart(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 40 40"
                className="h-8 w-8"
              >
                <g clip-path="url(#a)">
                  <path
                    d="M9.4 9.6 20 20.2m0 0L9.4 30.8M20 20.2l10.6 10.6M20 20.2 30.6 9.6"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h40v40H0z" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>

          {miniCartData?.settings?.freeShippingThreshold > 0 && (
            <div class="relative -mx-4 flex h-10 items-center justify-center bg-primary-2 md:-mx-15 md:h-16">
              <div class="type-text-7 md:type-text-6 relative z-10 border bg-primary-2 p-1">
                {percentage === 100
                  ? getTranslation('mini_cart.freeShippingCompleteMessage')
                  : getTranslation('mini_cart.freeShippingMessage', {
                      amount: formatMoney(difference, theme.moneyFormat),
                    })}
              </div>
              <div
                class="absolute inset-y-0 left-0 bg-primary-1"
                style={{ width: `${percentage.toFixed(2)}%` }}
              ></div>
            </div>
          )}
          <div
            class="-mx-4 flex flex-col-reverse overflow-auto px-4"
            data-cart-items
            data-scroll-lock-scrollable
          >
            <div>
              <CartCrossSell />
            </div>
            {cart.items && cart.items.length > 0 ? (
              cart.items.map(item => <CartItem item={item} key={item.key} />)
            ) : (
              <CartEmpty />
            )}
          </div>
          <hr class="-mx-4 mt-auto md:-mx-15" />
          <div>
            {cart.total_price > 0 && <CartTotals cart={{ ...cart }} />}

            <div class="mt-6 flex items-center justify-between">
              <button
                onClick={() => toggleMiniCart(false)}
                class="type-text-6 text-grey-2 underline hover:no-underline"
              >
                {getTranslation('mini_cart.keep_shopping')}
              </button>

              <form action={`${Shopify.routes.root}cart`} method="POST">
                <button
                  type="submit"
                  name="checkout"
                  class="button button--primary"
                >
                  {getTranslation('mini_cart.checkout')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>,
    MiniCartDrawerEl,
  );
};

export default MiniCart;
