import quickShop from '../components/product/ProductQuickShop';

const selectors = {
  quickShopTrigger: '[data-quickshop-trigger]',
};

export default class DynamicFeaturedProduct {
  constructor(section) {
    this.el = section.el;

    this._initQuickShop();
  }

  /**
   * Bind quickshop to a trigger
   */
  _initQuickShop = () => {
    const trigger = this.el.querySelector(selectors.quickShopTrigger);

    if (!trigger) {
      return;
    }

    trigger.addEventListener('click', () => {
      const { productUrl, productHash } = trigger.dataset;
      quickShop.open(productUrl, productHash);
    });
  };
}
