import ProductAddon from './ProductAddon';
import ProductForm from './ProductForm';
import ProductImages from './ProductImages';
import { formatMoney } from '@shopify/theme-currency';

const selectors = {
  priceWrapper: '[data-price-wrapper]',
  productPrice: '[data-product-price]',
  comparePrice: '[data-compare-price]',
  comparePriceText: '[data-compare-text]',
};

const cssClasses = {
  hidden: 'hidden',
};

/**
 * The wrapper class for PDP components
 *
 * @export
 * @class Product
 */
export default class Product {
  /**
   * Creates an instance of Product.
   *
   * @param {HTMLElement} el The container of this product
   * @param {Object} config Settings object
   */
  constructor(el, config = {}) {
    this.el = el;
    this.config = Object.assign({ isQuickShop: false }, config);

    this._initComponents();
  }

  /**
   * Init all components that make up the product
   */
  _initComponents() {
    this.productForm = new ProductForm(this.el, {
      onVariantChange: variant => this._onVariantChange(variant),
      isQuickShop: this.config.isQuickShop,
    });
    if (this.config.isQuickShop === false) {
      this.productImages = new ProductImages(this.el);
      this._tabComponents();
      this.productAddon = new ProductAddon(this.el);
    }
  }

  _tabComponents = () => {
    const tabs = this.el.querySelectorAll('[data-tab]');
    const tabContents = this.el.querySelectorAll('[data-tab-content]');
    if (tabs) {
      tabs.forEach(tab =>
        tab.addEventListener('click', () =>
          this._handleTabClick(tab, tabs, tabContents),
        ),
      );
    }
  };

  _handleTabClick = (tab, tabs, tabContents) => {
    tabs.forEach((t, i) => {
      if (t === tab) {
        t.classList.add('border-grey-1');
        t.classList.remove('border-transparent');
        tabContents[i].classList.add('block');
        tabContents[i].classList.remove('hidden');
      } else {
        t.classList.remove('border-grey-1');
        t.classList.add('border-transparent');
        tabContents[i].classList.add('hidden');
        tabContents[i].classList.remove('block');
      }
    });
  };

  /**
   * Call functions to update on variant change from product form
   *
   * @param {Object} variant
   */
  _onVariantChange = variant => {
    if (this.productImages) {
      this.productImages.change(variant);
    }
    this._updatePrice(variant);
  };

  /**
   * Update prices when selected variant changes
   *
   * @param {Object} variant
   */
  _updatePrice = variant => {
    const comparePrice = this.el.querySelector(selectors.comparePrice);
    const compareText = this.el.querySelector(selectors.comparePriceText);
    const mainPrice = this.el.querySelector(selectors.productPrice);

    const isOnSale = variant.compare_at_price > variant.price;
    const comparePriceText = isOnSale
      ? formatMoney(variant.compare_at_price, theme.moneyFormat)
      : '';

    // Update the product price
    mainPrice.innerHTML = formatMoney(variant.price, theme.moneyFormat);

    // Update compare at price depending on whether variant is on sale
    if (comparePrice && compareText) {
      comparePrice.innerHTML = comparePriceText;
      if (isOnSale) {
        comparePrice.classList.remove(cssClasses.hidden);
        compareText.classList.remove(cssClasses.hidden);
      } else {
        comparePrice.classList.add(cssClasses.hidden);
        compareText.classList.add(cssClasses.hidden);
      }
    }
  };
}
