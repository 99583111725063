import Scroll from '../helpers/scroll';

const selectors = {
  stickyHeader: '[sticky-header]',
};

export default class StickyHeader {
  constructor() {
    this.el = document.querySelector(selectors.stickyHeader);

    this.listener = new Scroll(this.onScroll, this.el);
    this.el.className +=
      ' sticky top-0 z-50 bg-grey-5 border-b border-grey-1 transition-transform';
  }

  onScroll = direction => {
    if (direction === 'up') {
      this.showMenu();
    } else if (direction === 'down') {
      this.hideMenu();
    }
  };

  showMenu = () => {
    this.el.classList.remove('-translate-y-full');
  };

  hideMenu = () => {
    this.el.classList.add('-translate-y-full');
  };
}
