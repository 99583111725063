import Splide from '@splidejs/splide';
import scrollLock from 'scroll-lock';
import { unitlessBreakpoints } from '../../helpers';

const selectors = {
  productImage: '[data-product-image]',
  galleryImage: '[data-gallery-image]',
  mainCarouselWrapper: '[data-image-carousel-wrapper]',
  mainCarousel: '[data-image-carousel]',
  thumbnailCarousel: '[data-thumbnail-carousel]',
  lightboxClose: '[data-close-lightbox]',
};

/**
 * Product image gallery on the PDP
 *
 * @export
 * @class ProductImages
 */
export default class ProductImages {
  constructor(el) {
    this.mainCarouselWrapper = el.querySelector(selectors.mainCarouselWrapper);
    this.mainCarousel = el.querySelector(selectors.mainCarousel);
    this.thumbnailCarousel = el.querySelector(selectors.thumbnailCarousel);
    this.navItems = this.thumbnailCarousel.querySelectorAll(
      selectors.productImage,
    );
    this.galleryImages = el.querySelectorAll(selectors.galleryImage);
    this.lightboxClose = el.querySelector(selectors.lightboxClose);
    this._initCarousel();
    this._initZoom();
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    this.mainCarousel = new Splide(this.mainCarousel, {
      perPage: 1,
      arrows: false,
      mediaQuery: 'min',
      breakpoints: {
        [unitlessBreakpoints.sm]: {
          pagination: false,
          arrows: true,
        },
      },
    });

    this.thumbnailCarousel = new Splide(this.thumbnailCarousel, {
      fixedWidth: 64,
      fixedHeight: 64,
      gap: 10,
      pagination: false,
      arrows: false,
      isNavigation: true,
      mediaQuery: 'min',
    });

    this.mainCarousel.sync(this.thumbnailCarousel);
    this.mainCarousel.mount();
    this.thumbnailCarousel.mount();
  }

  /**
   * Initialize image zoom functionality
   */
  _initZoom() {
    this.galleryImages.forEach((image, index) => {
      if (image.dataset.galleryImage === 'image') {
        image.addEventListener('click', () => {
          this.mainCarouselWrapper.classList.add('gallery-lightbox');
          this.mainCarousel.go(index);
          scrollLock.disablePageScroll();
        });
      }
    });

    this.lightboxClose.addEventListener('click', () => {
      this.mainCarouselWrapper.classList.remove('gallery-lightbox');
      scrollLock.enablePageScroll();
    });
  }

  /**
   * Handler for swapping images when the variant changes
   *
   * @param {Object} variant The currently selected variant
   * @public
   */
  change(variant) {
    this.navItems.forEach((element, index) => {
      const { imageVariants } = element.dataset;
      // Bail if array of variants for this image
      // doesn't contain currently selected variant id
      if (!imageVariants.split(',').includes(String(variant.id))) {
        return;
      }

      this.mainCarousel.go(index);
    });
  }
}
