import initCarousel from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

export default class DynamicFeaturedBlogPost {
  constructor(section) {
    const mobileItems = section.data.mobileItems;
    const tabletItems = section.data.tabletItems;
    const desktopItems = section.data.desktopItems;

    initCarousel(section.el, {
      perPage: mobileItems,
      mediaQuery: 'min',
      gap: '1.5rem',
      breakpoints: {
        [unitlessBreakpoints.md]: {
          perPage: tabletItems,
          gap: '3rem',
        },
        [unitlessBreakpoints.lg]: {
          perPage: desktopItems,
          gap: '4rem',
        },
      },
    });
  }
}
