import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { useEffect, useState } from 'preact/hooks';

const CartCrossSellProduct = ({ product }) => {
  if (!product) {
    return null;
  }

  const { addLineItem } = useCartContext();
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [crossSellImage, setCrossSellImage] = useState(
    product.images[0] ? product.images[0] : '',
  );

  useEffect(() => {
    if (product.variants.length > 0) {
      setSelectedVariant(product.variants[0]);
    }
  }, [product]);

  useEffect(() => {
    if (selectedVariant?.featured_image) {
      setCrossSellImage(selectedVariant.featured_image.src);
    }
  }, [selectedVariant]);

  const handleOptionChange = option => {
    const variantSelected = product.variants.find(variant =>
      variant.options.includes(option),
    );
    setSelectedVariant(variantSelected);
  };
  if (!selectedVariant || !selectedVariant.id) {
    return null;
  }

  const handleSubmit = event => {
    event.preventDefault();
    addLineItem(event);
  };

  return (
    <form
      method="post"
      action="/cart/add"
      onSubmit={handleSubmit}
      class="flex items-start space-x-6 border border-grey-1 bg-primary-2 p-4"
      data-product-id={product.id}
    >
      <div class="aspect-square w-16 bg-white">
        <img
          src={crossSellImage}
          class=" h-full w-full border border-grey-1 object-cover"
        />
      </div>
      <div class="flex-1 md:flex md:items-start">
        <div class="flex-1">
          <h5 class="type-text-8b mb-1 text-primary-1">
            {getTranslation('mini_cart.add_on')}
          </h5>
          <h4 class="type-text-6">{product.title}</h4>
          <div class="type-text-6 mb-2 mt-1 flex flex-row items-center">
            <span>{formatMoney(selectedVariant.price, theme.moneyFormat)}</span>
            {selectedVariant.compare_at_price > selectedVariant.price && (
              <>
                <span class="sr-only">
                  {getTranslation('products.regular_price')}
                </span>
                <span class="ml-5 text-grey-2 line-through">
                  {formatMoney(
                    selectedVariant.compare_at_price,
                    theme.moneyFormat,
                  )}
                </span>
              </>
            )}
          </div>

          {product.options.length > 0 &&
            product.options.map(option => {
              if (option.values.length <= 1) {
                return '';
              }
              return (
                <select
                  name={`options[${option.name}]`}
                  class="type-text-8 mb-2 block w-full appearance-none sm:w-48 md:mb-0"
                  onChange={e => handleOptionChange(e.target.value)}
                >
                  {option.values.map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              );
            })}
        </div>
        <button
          class="button button--secondary w-full sm:w-48 md:w-auto"
          disabled={!selectedVariant.available}
        >
          {selectedVariant.available
            ? getTranslation('mini_cart.add_to_cart')
            : getTranslation('mini_cart.sold_out')}
        </button>
      </div>

      <input type="hidden" name="form_type" value="product" />
      <input type="hidden" name="utf8" value="✓" />
      <input type="hidden" name="id" value={selectedVariant.id} />
      <input type="hidden" name="quantity" value="1" />
    </form>
  );
};

export default CartCrossSellProduct;
