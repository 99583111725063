const H_Count = 6;

export default class MainArticle {
  constructor() {
    const selectorBase = 'body.template-article .article-body';
    let selector = '';

    for (let i = 1; i <= H_Count; i++) {
      selector += ''
        .concat(selectorBase, ' h')
        .concat(i)
        .concat(i < H_Count ? ', ' : '', ' ');
    }

    document.querySelectorAll(selector).forEach(heading => {
      heading.setAttribute('id', this._toKebabCase(heading.innerText));
    });
  }

  _toKebabCase(str) {
    return (
      str &&
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
        )
        .map(x => {
          return x.toLowerCase();
        })
        .join('-')
    );
  }
}
