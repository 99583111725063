export default class LocalizationForm {
  constructor(selector) {
    this.el = selector;
    this.elements = {
      input: this.el.querySelector(
        'input[name="language_code"], input[name="country_code"]',
      ),
      button: this.el.querySelector('button'),
      panel: this.el.querySelector('ul'),
    };

    this.elements.button.addEventListener(
      'click',
      this.openSelector.bind(this),
    );
    this.elements.button.addEventListener(
      'focusout',
      this.closeSelector.bind(this),
    );
    this.el.addEventListener('keyup', this.onContainerKeyUp.bind(this));

    this.el
      .querySelectorAll('a')
      .forEach(item =>
        item.addEventListener('click', this.onItemClick.bind(this)),
      );
  }

  hidePanel() {
    this.elements.button.setAttribute('aria-expanded', 'false');
    this.elements.panel.setAttribute('hidden', true);
  }

  onContainerKeyUp(event) {
    if (event.code.toUpperCase() !== 'ESCAPE') {
      return;
    }

    this.hidePanel();
    this.elements.button.focus();
  }

  onItemClick(event) {
    event.preventDefault();
    const form = this.el.querySelector('form');
    this.elements.input.value = event.currentTarget.dataset.value;
    if (form) {
      form.submit();
    }
  }

  openSelector() {
    this.elements.button.focus();
    this.elements.panel.toggleAttribute('hidden');
    this.elements.button.setAttribute(
      'aria-expanded',
      (
        this.elements.button.getAttribute('aria-expanded') === 'false'
      ).toString(),
    );
  }

  closeSelector(event) {
    const shouldClose =
      event.relatedTarget && event.relatedTarget.nodeName === 'BUTTON';
    if (event.relatedTarget === null || shouldClose) {
      this.hidePanel();
    }
  }
}
