import Splide from '@splidejs/splide';
import { unitlessBreakpoints } from '../helpers/breakpoints';

const selectors = {
  featuredCarousel: '[data-centered-feature-carousel]',
};

export default class DynamicCenteredFeature {
  constructor(section) {
    const featuredCarousel = section.el.querySelector(
      selectors.featuredCarousel,
    );

    if (featuredCarousel) {
      const featured = new Splide(featuredCarousel, {
        perPage: 1,
        perMove: 1,
        pagination: true,
        rewind: true,
        mediaQuery: 'min',
        breakpoints: {
          [unitlessBreakpoints.md]: {
            destroy: true,
          },
        },
      });

      featured.mount();
    }
  }
}
