import { createPortal } from 'preact/compat';
import { getTranslation } from '../helpers/utilities';
import { useCartContext } from '../context/CartContext';
import useEmptyPortal from '../hooks/useEmptyPortal';

import { h } from 'preact';

const MiniCartEl = document.getElementById('preact-mini-cart');

const MiniCart = () => {
  const { cart, toggleMiniCart } = useCartContext();
  const [emptied, initialHtml] = useEmptyPortal(MiniCartEl);

  if (!emptied) {
    return null;
  }

  return createPortal(
    <button
      class="button button--link relative block"
      aria-label={getTranslation('header.cart')}
      onClick={() => {
        toggleMiniCart(true);
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: initialHtml }} />
      {cart.item_count > 0 && (
        <div className="absolute top-[3px] right-[2px] h-3.5 w-3.5 rounded-full border-2 border-white bg-primary-1"></div>
      )}
    </button>,
    MiniCartEl,
  );
};

export default MiniCart;
