import focusTrap from 'focus-trap';

const selectors = {
  applyFilters: '[data-filter-apply]',
  cancelButton: '[data-filter-cancel]',
  filterInput: '[data-filter-input]',
  openFilters: '[data-filter-drawer-toggle]',
  expander: '[data-filter-expander]',
  sorts: '[data-sort-by]',
  drawer: '[data-filter-drawer]',
};

export default class Filters {
  constructor(form) {
    this.el = form;
    this.drawer = this.el.querySelector(selectors.drawer);
    this.bodyEl = document.querySelector('.template-collection');
    this._submitForm = this._submitForm.bind(this);
    this._openDrawer = this._openDrawer.bind(this);

    this.drawerIsOpen = false;

    this._initFilters();

    this.focusTrap = focusTrap(selectors.drawer, {
      onActivate: () => {
        this.el.classList.add('focus-trap');
      },
      onDeactivate: () => {
        this.el.classList.remove('focus-trap');
      },
      initialFocus: () => document.querySelector(selectors.drawer),
      clickOutsideDeactivates: false,
    });
  }

  _initFilters() {
    this._attachListeners(selectors.filterInput, 'onchange', () => {
      if (!this.drawerIsOpen) {
        this._submitForm();
      }
    });
    this._attachListeners(selectors.applyFilters, 'onclick', () => {
      this._submitForm();
    });
    this._attachListeners(selectors.expander, 'onclick', e => {
      this._expand(e);
    });
    this._attachListeners(selectors.sorts, 'onchange', () => {
      this._submitForm();
    });
    this._attachListeners(selectors.cancelButton, 'onclick', () => {
      this._closeDrawer();
    });
    this._attachListeners(selectors.openFilters, 'onclick', () => {
      this._openDrawer();
    });
  }

  _attachListeners(selector, listenerType, func) {
    const domNodes = this.el.querySelectorAll(selector);

    if (domNodes.length > 0) {
      for (const node of domNodes) {
        node[listenerType] = func;
      }
    }
  }

  _submitForm() {
    document.filterForm.submit();
  }

  _openDrawer() {
    this.drawer.style.transform = 'translateX(0)';
    this.drawer.classList.remove('hidden');
    this.bodyEl.classList.add('filter-drawer-open');
    this.drawerIsOpen = true;
    this.focusTrap.activate();
  }

  _closeDrawer() {
    this.drawer.style.transform = 'translateX(100%)';
    this.drawer.classList.add('hidden');
    this.bodyEl.classList.remove('filter-drawer-open');
    this.drawerIsOpen = false;
    this.focusTrap.deactivate();
  }

  _expand(e) {
    e.preventDefault();
    const filterName = e.target.id.split('-')[1];
    const divToExpand = document.getElementById(
      'hidden_drawer-filter-' + filterName,
    );
    const expanderLabel = document.getElementById(
      'expander_drawer-' + filterName,
    );
    if (expanderLabel.innerHTML.trim() == 'Show more') {
      expanderLabel.innerHTML = 'Show less';
      divToExpand.style.display = 'flex';
    } else {
      expanderLabel.innerHTML = 'Show more';
      divToExpand.style.display = 'None';
    }
  }
}
