import ProductItem from '../components/product/ProductItem';
import initCarousel from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

const selectors = {
  productItem: '[data-product-item]',
};

export default class DynamicFeaturedCollection {
  constructor(section) {
    const mobileItems = section.data.mobileItems;
    const tabletItems = section.data.tabletItems;
    const desktopItems = section.data.desktopItems;

    // Init product items
    const productItems = section.el.querySelectorAll(selectors.productItem);
    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    initCarousel(section.el, {
      perPage: 1,
      mediaQuery: 'min',
      breakpoints: {
        400: {
          perPage: mobileItems,
        },
        [unitlessBreakpoints.md]: {
          perPage: tabletItems,
        },
        [unitlessBreakpoints.lg]: {
          perPage: desktopItems,
        },
      },
    });
  }
}
