import Splide from '@splidejs/splide';
import { unitlessBreakpoints } from '../helpers';

const selectors = {
  mainCarousel: '[data-splide-carousel]',
  thumbnailCarousel: '[data-splide-thumbnail-carousel]',
};

export default class DynamicBrandTestimonials {
  constructor(section) {
    const carousel = section.el.querySelector(selectors.mainCarousel);
    const thumbnailCarousel = section.el.querySelector(
      selectors.thumbnailCarousel,
    );

    if (carousel && thumbnailCarousel) {
      const main = new Splide(carousel, {
        perMove: 1,
        type: 'fade',
        rewind: true,
        arrows: true,
        pagination: false,
        mediaQuery: 'min',
        breakpoints: {
          [unitlessBreakpoints.lg]: {
            arrows: false,
          },
        },
      });

      const thumbnails = new Splide(thumbnailCarousel, {
        fixedWidth: 130,
        arrows: false,
        gap: '2rem',
        pagination: false,
        isNavigation: true,
      });

      main.sync(thumbnails);
      main.mount();
      thumbnails.mount();
    }
  }
}
