import * as cartApi from '@knitagency/agency-utils/dist/cartApi';
import { updateReactCart } from '../../preact/helpers/utilities';

const selectors = {
  form: '[data-product-addon-form]',
};

/**
 * Configuration of the PDP Addon
 *
 * @export
 * @class ProductAddon
 */
export default class ProductAddon {
  /**
   * Creates an instance of ProductAddon.
   * @param {HTMLElement} productContainer The container of this product
   */
  constructor(productContainer) {
    this.el = productContainer;
    this.formElement = this.el.querySelector(selectors.form);

    if (this.formElement) {
      this.formElement.addEventListener('submit', this._onFormSubmit);
    }
  }

  _onFormSubmit = event => {
    event.preventDefault();

    cartApi
      .add(event.target)
      .then(() => {
        updateReactCart(true);
      })
      .catch(response => this._handleCartApiError(response));
  };
}
