import CartCrossSellProduct from './CartCrossSellProduct';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { useEffect, useState } from 'preact/hooks';

const CartCrossSell = () => {
  const { cart, isMiniCartOpen } = useCartContext();
  const [productIds, setProductIds] = useState([]);
  const [crossSells, setCrossSells] = useState([]);
  const [crossSellItems, setCrossSellItems] = useState([]);
  const crossSellItemLimit = 1;
  const locale = Shopify.country === 'US' ? 'US' : 'Canada';

  useEffect(() => {
    setCrossSells([]);
    setProductIds([]);

    if (isMiniCartOpen && cart && cart.items.length > 0) {
      setProductIds(cart.items.map(item => item.product_id));
      setCrossSells(
        cart.items
          .map(item => item.cross_sell_item)
          .filter(item => {
            if (item !== null) {
              if (item.region && item?.region?.includes(locale)) {
                return item;
              } else if (!item.region) {
                return item;
              }
            }
            return null;
          }),
      );
    }
  }, [cart.items, isMiniCartOpen]);

  useEffect(() => {
    if (isMiniCartOpen == true) {
      const filteredCrossSells = crossSells.filter(
        crossSell => crossSell.id && !productIds.includes(crossSell.id),
      );
      setCrossSellItems(filteredCrossSells);
    }
  }, [crossSells, isMiniCartOpen]);

  return crossSellItems && crossSellItems.length > 0 ? (
    <div class="relative py-8">
      <div>
        {(crossSellItems || []).slice(0, crossSellItemLimit).map(item => (
          <CartCrossSellProduct product={item} />
        ))}
      </div>
    </div>
  ) : null;
};

export default CartCrossSell;
