import Splide from '@splidejs/splide';
import { unitlessBreakpoints } from '../helpers/breakpoints';

const selectors = {
  mainCarousel: '[data-comparison-carousel]',
};

export default class DynamicComparisonTable {
  constructor(section) {
    const carousel = section.el.querySelector(selectors.mainCarousel);

    if (carousel) {
      const mainCarousel = new Splide(carousel, {
        perPage: 1,
        perMove: 1,
        arrows: false,
        type: 'loop',
        mediaQuery: 'min',
        breakpoints: {
          [unitlessBreakpoints.md]: {
            destroy: true,
          },
        },
      });

      mainCarousel.mount();
    }
  }
}
