import Filters from '../components/Filters';
import ProductItem from '../components/product/ProductItem';

const selectors = {
  productItems: '[data-product-item]',
  filterForm: '[data-filter-form]',
};

export default class MainSearch {
  constructor(section) {
    const productItems = section.el.querySelectorAll(selectors.productItems);
    const filterForms = section.el.querySelectorAll(selectors.filterForm);

    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    [...filterForms].forEach(form => {
      new Filters(form);
    });
  }
}
